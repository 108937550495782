import { BASE_URL } from '../../constants/defaultValues'
import Dictionary from '../dictionary'
import axios from 'axios'

const { UploadDocuments } = Dictionary

const Upload_Documents = {
  add: async (data: {}) => {
    return axios.post(BASE_URL + UploadDocuments.add(), data)
  },
  getById: async (id: string,download:boolean) => {
    return axios.get(BASE_URL + UploadDocuments.getById(id,download))
  },
  deleteById: async (id:string) => {
    return axios.delete(BASE_URL + UploadDocuments.deleteById(id))
  },
  downloadFile: async (paramsObj: {}, config: { signal: AbortSignal; responseType: any }) => {
    return axios.get(BASE_URL + UploadDocuments.downloadFile(), {
      params: paramsObj,
      signal: config.signal,
      responseType: config.responseType,
    });
  }
}
export default Upload_Documents

import React, { memo, useEffect, useState } from 'react'
import DataTable from 'react-data-table-component';
import Pagination from '../Pagination';
import './style.scss'
import { Grid, } from '@mui/material';
import { useResponsive } from '../useResponsive';
const Index = ({
  columns, data,
  paginationTotalRows,
  setCurrentPage,
  setLimitPerPage,limit,
  pagination, className,handleSort }: any) => {

 const {windowWidth,loginDetail,isSmallScreen} = useResponsive();
 const {autoCaseloadCreation} = loginDetail?.organization
 
  const customStyles = {
    headCells: {
      style: {
        border: 'none',
        marginTop: '3rem',
        fontWeight: '500',
        paddingLeft: '8px', // override the cell padding for head cells
        paddingRight: '8px',
        paddingTop: '15px',
        paddingBottom: '15px',
        // background:'#515862',
        // borderBottom: '1px solid  background-color: rgba(81, 88, 98, 0.5); /* #515862 with 50% opacity */',
        fontSize: '14px',
        fontFamily: 'Inter',
        color: '#212B36',

      },
    },
    rows: {
      style: {
        border: 'none',
        minHeight: '60px', 
        // border: '1px solid  background-color: rgba(81, 88, 98, 0.5); /* #ffffff with 50% opacity */',
      },
    },
    cells: {
      style: {
        border: 'none',
        borderBottom: 'none',
        paddingLeft: '8px', 
        paddingRight: '8px',
      },

    },
  };

  const tableWidth = () => {
    const sidebarWidth = isSmallScreen ? 0 : 240
    const adjustedWidth = windowWidth - sidebarWidth; 
    return `calc(${adjustedWidth}px)`;
  };

  document.documentElement.style.setProperty('--vh', `${window.innerHeight}px`);


  useEffect(()=>{
 if(autoCaseloadCreation){
  document.documentElement.style.setProperty('--tblHeight', `290px`);
 }else{
  document.documentElement.style.setProperty('--tblHeight', `290px`);
 }
  },[autoCaseloadCreation])

  

  return (
    <Grid sx={{maxWidth:{
      lg:tableWidth(),
      md:tableWidth(),
      sm:tableWidth(),
      xs:'100%'
    },width:'100%'}}><DataTable
      className={`tableFixeHeight ${className}`}
      customStyles={customStyles}
      columns={columns}
      data={data}
      onSort={handleSort}
      pagination={false}
      striped
      style={{overflow:'auto'}}
    />

      {pagination && data?.length !== 0 && <Pagination totalPages={paginationTotalRows} 
      onCurrentPage={(page: number) => setCurrentPage(page)} limit={limit} setLimit={(e:any)=>setLimitPerPage(e)} />}
    </Grid>
  )
}

export default memo(Index)
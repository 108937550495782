import { VIEW_MODULES, ADD_ADMIN_ACCOUNT, MODULES_RESPONSE, USER_RESPONSE,
     CLINICIAN_LISTING, USER_LISTING, USER_DETAIL_RESPONSE, GET_OPEN_USER_DETAIL,GET_USER_DETAIL,UPDATE_USER_ACCOUNT, RE_ACTIVATION_MAIL_By_ID, STATUS_CHANGED_By_ID, USER_FULL_LISTING, USER_FULL_RESPONSE, DELETE_USER_BY_ID, ADD_AddITIONAL_PARENT_BY_ID, UPDATE_AddITIONAL_PARENT_BY_ID, USER_FULL_RESPONSE_CLEAR, VERIFY_EMAIL, CLEAR_EMAIL_RESPONSE, VERIFY_EMAIL_RESPONSE, UPDATE_EMAIL_RESPONSE, UPDATE_EMAIL, UPDATE_OVERVIEW_USER } from './actionType'
export const addAdminAccount = (questionData: any, navigate: any, param: string) => {
    return {
        type: ADD_ADMIN_ACCOUNT,
        questionData,
        navigate,
        param
    }
}
export const updateUserAccount = (id:string,questionData: any, navigate: any, param: string) => {
    return {
        type: UPDATE_USER_ACCOUNT,
        questionData,
        navigate,
        param,
        id
    }
}

export const updateOverViewParent = (caseloadId:string,id:string,data: any) => {
    return {
        type: UPDATE_OVERVIEW_USER,
        caseloadId,
        data,
        id
    }
}


export const getUserList = (headers: any) => {
    const type = headers?.limit === '' && headers?.page === '' ? USER_FULL_LISTING : USER_LISTING;
    return {
        type,
        headers
    }
}
export const getOpenUserList = (headers: any) => {
    const type = GET_OPEN_USER_DETAIL
    return {
        type,
        headers
    }
}

export const reActivationbyId = (id: any) => {
  
    return {
        type: RE_ACTIVATION_MAIL_By_ID,
        id,
    }
}

export const statusChangedbyId = (id: any,data: any,listing:any) => {
  
    return {
        type: STATUS_CHANGED_By_ID,
        id,
        data,
        listing
    }
}

export const addOtherParentById = (data: any) => {
  
    return {
        type: ADD_AddITIONAL_PARENT_BY_ID,
        data,
    }
}

export const updateOtherParentById = (datax:any, data: any) => {
  
    return {
        datax,
        type: UPDATE_AddITIONAL_PARENT_BY_ID,
        data,
    }
}




export const getUserByID = (id: string) => {

    return {
        type: GET_USER_DETAIL,
        id
    }
}

export const deleteUserByID = (id: string,listing:any) => {

    return {
        type: DELETE_USER_BY_ID,
        listing,
        id
    }
}

export const getClinicianList = (headers: any) => {
    return {
        type: CLINICIAN_LISTING,
        headers
    }
}
export const clearUserDataState = (data: any) => {
    
    return {
        type: USER_RESPONSE,
        payload: data
    }
}

export const getClearGetDataByID = () => {
    return {
        type: USER_DETAIL_RESPONSE,
        payload:''
    }
}


export const getModules = (headers: any) => {
    return {
        type: VIEW_MODULES,
        headers
    }
}
// export const clinicianResponse = (headers: any) => {
//     return {
//         type: CLINICIAN_RESPONSE,
//         headers
//     }
// }
export const userResponse = (Response: any) => {
    return {
        type: USER_RESPONSE,
        payload: Response,
    }
}
export const userFullResponse = (Response: any) => {
    return {
        type: USER_FULL_RESPONSE,
        payload: Response,
    }
}

export const modulesResponse = (Response: any) => {
    return {
        type: MODULES_RESPONSE,
        payload: Response,
    }
}
export const getUserDetail = (Response: any) => {
    return {
        type: USER_DETAIL_RESPONSE,
        payload: Response,
    }
}

export const clearFullUserData = () => {
    return {
        type : USER_FULL_RESPONSE_CLEAR,
        payload : ''
    }
}

export const updateEmail = (data: any) => {
    return {
        type: UPDATE_EMAIL,
        data
    }
}

export const updateEmailResponse = (Response: any) => {
    return {
        type: UPDATE_EMAIL_RESPONSE,
        payload: Response,
    }
}

export const verifyEmailResponse = (Response: any) => {
    return {
        type: VERIFY_EMAIL_RESPONSE,
        payload: Response,
    }
}

export const clearEmailResponse = () => {
    return {
        type: CLEAR_EMAIL_RESPONSE,
    }
}


export const verifyEmail = (data: any) => {
    return {
        type: VERIFY_EMAIL,
        data
    }
}
export const styles = {
  topTab: {
    width: "100%",
    justifyContent: "space-between",
    display: "flex",
    borderBottom: "1px solid #CCCCCC",
    mb: "1rem",
  },
  caseloadWrapper: {
    maxWidth: {
      xs: "100%",
      lg: "100%",
      md: "750px",
      sm: "100%",
    },
  },
  tabCard: {
    p: 0,
    background: {
      xs: "none",
      lg: "#fff",
      md: "#fff",
      sm: "#fff",
    },
    borderColor: {
      xs: "transparent",
      lg: "#0000001f",
      md: "#0000001f",
      sm: "transparent",
    },
  },
  tabResponsive: {
    width: "100%",
    "& div": {
      position: "static",
    },
    ".MuiTabs-flexContainer": {
      "&::-webkit-scrollbar": {
        width: "0",
        height:'3px'
      },
      "&::-webkit-scrollbar-track": {
        boxShadow: "none",
      },
      "&::-webkit-scrollbar-thumb": {
        backgroundColor: "lightgrey",
        borderRadius: "0",
      },
      m: {
        xs: "0",
        lg: "1rem 1rem 0rem",
        md: "0",
        sm: "0",
      },
      flexDirection: {
        xs: "column",
        lg: "row",
        md: "row",
        sm: "column",
      },
      overflow: {
        xs: "auto",
        lg: "visible",
        md: "auto",
        sm: "auto",
      },
      maxWidth: {
        xs: "100%",
        md: "750px",
        lg: "100%",
        sm: "100%",
      },
    },
    "& button": {
      display: "flex",
      alignItems: {
        xs: "start",
        lg: "center",
        md: "center",
        sm: "start",
      },
      margin: "auto",
      width: {
        xs: "100%",
        lg: "12%",
        md: "auto",
        sm: "100%",
      },
      minWidth: {
        xs: "100%",
        lg: "auto",
        md: "auto",
        sm: "100%",
      },
      maxWidth: {
        xs: "100%",
        lg: "auto",
        md: "100%",
        sm: "100%",
      },
    },
    ".MuiTabs-indicator": {
      display: {
        xs: "none",
        lg: "block",
        md: "block",
        sm: "none",
      },
    },
  },
  stepperContent: {
    padding: {
      xs: 0,
      md: 2,
      lg: 2,
      sm: 2,
    },
    maxHeight: {
      xs: "auto",
      md: "calc(100vh - 350px)",
      lg: "calc(100vh - 350px)",
      sm: "auto",
    },
    minHeight: {
      xs: "auto",
      md: "calc(100vh - 350px)",
      lg: "calc(100vh - 350px)",
      sm: "auto",
    },
    "div.MuiGrid-root": {
      margin: {
        xs: "0rem",
        md: "inherit",
        lg: "inherit",
        sm: "0rem",
      },
    },
  },
  overflowEilps: {
    color: "#444C55",
    display: "-webkit-box",
    textTransform: "capitalize",
    WebkitBoxOrient: "vertical",
    WebkitLineClamp: 1,
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  counterLabel: {
    ".MuiStepLabel-labelContainer": {
      display: {
        xs: "none",
        lg: "block",
        md: "block",
        sm: "block",
      },
    },
  },
  topSpace:{ mt: { md: "0" }, ml: { md: "0" }, width: "100%" }
};

export const style = {
  non_verified_button: {
    textTransform: "none",
    color: "#656565",
    borderColor: "#656565",
    marginRight: "1rem",
  },

  font_custom_style: { fontSize: "14px", color: "#444C55" },
  card_custom: {
    borderRadius: "10px",
    height: "100%",
    maxHeight: {
      xs: 'auto',
      md: '400px',
      lg: '400px',
      sm: '400px',  
    },
    minHeight: {
      xs: 'auto',
      md: '290px',
      lg: '290px',
      sm: '290px',  
    },
    position: "relative",
  },
  label_color: {
    color: "#212B36",
    fontFamily: "poppins",
    marginBottom: "0.5rem",
    fontWeight: 600,
    textTransform: "none",
  },
  eduCard :{
    display: "flex",
    gap: "5px",
    alignItems: "start",
    mb: "0!important",
    flexDirection: {
      xs: "column",
      lg: "row",
      md: "column",
      sm: "column",
    },
  },
  notRegister:{
    background: "#ff4949",
    borderRadius: "1rem",
    padding: "0.1rem 0.7rem",
    color: " #fff",
    fontWeight: 600,
    fontSize: "0.7rem",
  },
  noAccess:{
    background: "#cd9713",
    borderRadius: "1rem",
    padding: "0.1rem 0.7rem",
    color: " #fff",
    fontWeight: 600,
    fontSize: "0.7rem",
  },
  homeSchool:{
    color: "#6A2382",
    fontFamily: "poppins",
    display: "flex",
    gap: "0.5rem",
    alignItems: "center",
    fontSize: "0.8rem",
  },
  clinicianTab:{
    display: "flex",
    justifyContent: "space-between",
    mb: "15px",
    pb: "15px",
    alignItems: "center",
  },
  cardGrid:{
    flexWrap: {
      lg: "nowrap",
      xs: "wrap",
      md: "nowrap",
      sm: "wrap",
    },
    gap: {
      lg: "1rem",
      xs: "0rem",
      md: "1rem",
      sm: "0rem",
    },
  },
  addBtnWrapper:{
    display: "flex",
    justifyContent: "center",
    alignItem: "center",
    mt: "15px",
  },
  clinicalTable:{
    width: "100%",
    "> div": {
      maxWidth: "100%",
    },
  }
};

export const question: any = {
  personalDetail: [
    {
      question_id: 'ques1',
      question_number: 1,
      question: 'Name',
      answer: '',
      type: 'text',
      option: '',
      isRequired: true,
      isAdditional: false,
      placeholder: '',
      isCompulsory: true,
    },
    {
      question_id: 'ques2',
      question_number: 2,
      question: 'Date of Birth',
      answer: '',
      type: 'date',
      option: '',
      isRequired: true,
      isAdditional: false,
      isCompulsory: true,
    },
    {
      question_id: 'ques3',
      question_number: 3,
      question:
        'Preschool/nursery or education setting attended (state if the CYP is home educated).',
      answer: '',
      type: 'text',
      option: '',
      isRequired: true,
      isAdditional: false,
      isCompulsory: true,
    },
    {
      question_id: 'ques4',
      question_number: 4,
      question: 'Person Filling the Form',
      answer: '',
      type: 'text',
      option: '',
      isRequired: true,
      isAdditional: false,
      isCompulsory: true,
    },
    {
      question_id: 'ques5',
      question_number: 5,
      question: 'Title/Job role',
      answer: '',
      type: 'text',
      option: ' ',
      isRequired: true,
      isAdditional: false,
      isCompulsory: true,
    },
    {
      question_id: 'ques6',
      question_number: 6,
      question: 'Education Setting Email',
      answer: '',
      type: 'email',
      option: ' ',
      isRequired: true,
      isAdditional: false,
      isCompulsory: true,
    },
    {
      question_id: 'ques7',
      question_number: 7,
      question: 'Please summarise your concerns.',
      answer: '',
      type: 'textArea',
      option: ' ',
      isRequired: true,
      isAdditional: false,
      isCompulsory: true,
    },
    {
      question_id: 'ques8',
      question_number: 8,
      question: `Date of Completion`,
      answer: `${new Date()}`,
      type: 'date',
      option: [],
      isRequired: true,
      isAdditional: false,
      isCompulsory: true,
    },
  ],

  communication: [
    {
      question_id: 'ques9',
      question: `Please describe patient_name communication at school. Comment on who, how and why they communicate – for example: to express needs, to give information, to share experiences, to have a to and fro conversation.`,
      additional_info: `If patient_name is non-speaking, please state what methods they do use.`,
      question_number: 9,
      answer: '',
      type: 'textArea',
      isRequired: true,
      option: '',
      isAdditional: false,
      isCompulsory: true,
    },
    {
      question_id: 'ques10',
      question_number: 10,
      question: `Please describe how patient_name responds to instructions and if  you have any concerns around their listening and understanding of language.`,
      answer: '',
      type: 'textArea',
      option: '',
      isRequired: true,
      isAdditional: false,
      isCompulsory: true,
    },
    {
      question_id: 'ques11',
      question_number: 11,
      question: `Please describe how patient_name uses non-verbal communication. For example - gesture, eye contact, facial expressions, tone of voice.`,
      answer: '',
      type: 'textArea',
      option: '',
      isRequired: true,
      isAdditional: false,
      isCompulsory: true,
    },
    {
      question_id: 'ques12',
      question_number: 12,
      question: `Describe how / if patient_name asks for help to seek comfort and problem solve.`,
      answer: '',
      type: 'textArea',
      option: '',
      isRequired: true,
      isAdditional: false,
      isCompulsory: true,
    },
    {
      question_id: 'ques13',
      question_number: 13,
      question: `Is patient_name able to initiate and engage in a sustained two-way conversation where there is an easy to and fro in the conversation?`,
      answer: '',
      type: 'radio',
      option: ['Yes', 'No'],
      isRequired: true,
      isAdditional: false,
      isCompulsory: true,
    },
    {
      question_id: 'ques14',
      question_number: 14,
      question: 'With peers?',
      answer: '',
      type: 'textArea',
      option: '',
      isRequired: false,
      isAdditional: false,
      isCompulsory: false,
    },
    {
      question_id: 'ques15',
      question_number: 15,
      question: 'With adults?',
      answer: '',
      type: 'textArea',
      option: '',
      isRequired: false,
      isAdditional: false,
      isCompulsory: false,
    },
    {
      question_id: 'ques16',
      question_number: 16,
      question: `Does patient_name have any unusual characteristics in their language? E.g. formal 'language',accent,tone,pitch,repetitive speech,learnt phrases.`,
      answer: '',
      type: 'textArea',
      option: '',
      isRequired: true,
      isAdditional: false,
      isCompulsory: false,
    },
  ],

  socialInteraction: [
    {
      question_id: 'ques17',
      question_number: 17,
      question: `Does patient_name respond to their name?`,
      answer: '',
      type: 'textArea',
      option: '',
      isRequired: true,
      isCompulsory: true,
    },
    {
      question_id: 'ques18',
      question_number: 18,
      question: `Can patient_name share with adults and/ or their peers about things that are happening in their life, and about their experiences, thoughts, and opinions with others?`,
      answer: '',
      type: 'textArea',
      option: '',
      isRequired: true,
      isCompulsory: true,
    },
    {
      question_id: 'ques19',
      question_number: 19,
      question: `Does patient_name show sensitivity towards others' needs, feelings , experiences and achievements?`,
      answer: '',
      isCompulsory: true,
      type: 'radio',
      option: ['Yes', 'No'],
      isRequired: true,
      isAdditional: true,
      isAdditionalStructure: {
        parent_id: 'ques19',
        question_id: 'ques1',
        question_number: 1,
        question: '',
        isRequired: false,
        type: 'textArea',
        answer: '',
        option: '',
        isCompulsory: true,
      },
    },
    {
      question_id: 'ques20',
      question_number: 20,
      question: `Is patient_name able to cooperate with adults and peers in small groups / large class settings?`,
      answer: '',
      type: 'radio',
      isCompulsory: true,
      option: ['Yes', 'No'],
      isRequired: true,
      isAdditional: true,
      isAdditionalStructure: {
        parent_id: 'ques20',
        question_id: 'ques1',
        question_number: 1,
        question: '',
        isRequired: false,
        type: 'textArea',
        answer: '',
        option: '',
        isCompulsory: true,
      },
    },
    {
      question_id: 'ques21',
      question_number: 21,
      question: `Please describe how patient_name interacts with others and how they form and maintain friendships.`,
      answer: '',
      isCompulsory: true,
      type: 'textArea',
      option: '',
      isRequired: true,
    },
    {
      question_id: 'ques22',
      question_number: 22,
      question: `Does patient_name show bigger emotional reactions to situations than their peers?`,
      answer: '',
      type: 'radio',
      isCompulsory: true,
      option: ['Yes', 'No'],
      isRequired: true,
      isAdditional: true,
      isAdditionalStructure: {
        parent_id: 'ques22',
        question_id: 'ques1',
        question_number: 1,
        question: 'How does patient_name cope with these emotions?',
        isRequired: false,
        type: 'textArea',
        answer: '',
        option: '',
        isCompulsory: true,
      },
    },
   
    {
      question_id: 'ques23',
      question_number: 23,
      question: `Does patient_name modify their behaviour to fit with expectations in different situations? E.g., in assembly/ in the playground/ with adults?`,
      answer: '',
      isCompulsory: true,
      type: 'radio',
      option: ['Yes', 'No'],
      isRequired: true,
      isAdditional: true,
      isAdditionalStructure: {
        parent_id: 'ques23',
        question_id: 'ques1',
        question_number: 1,
        question: '',
        isRequired: false,
        type: 'textArea',
        answer: '',
        option: '',
        isCompulsory: true,
      },
    },
  ],

  creativityImagination: [
    {
      question_id: 'ques24',
      question_number: 24,
      question: `Does patient_name demonstrate creativity/imagination in situations such as play, written work and art?`,
      answer: '',
      type: 'textArea',
      option: '',
      isRequired: true,
      isAdditional: false,
      isCompulsory: true,
    },
    {
      question_id: 'ques25',
      question_number: 25,
      question: `Please give details of any focussed interests or specific play preferences that patient_name may have.`,
      answer: '',
      type: 'textArea',
      option: '',
      isRequired: true,
      isAdditional: false,
      isCompulsory: true,
    },
  ],

  behaviour: [
    {
      question_id: 'ques26',
      question_number: 26,
      question: `Does patient_name display any repetitive behaviours? (e.g. spinning objects, lining up toys, sorting)?`,
      answer: '',
      isCompulsory: true,
      type: 'radio',
      option: ['Yes', 'No'],
      isRequired: true,
      isAdditional: true,
      isAdditionalStructure: {
        parent_id: 'ques26',
        question_id: 'ques1',
        question_number: 1,
        question: '',
        isRequired: false,
        type: 'textArea',
        answer: '',
        option: '',
        isCompulsory: true,
      },
    },
    {
      question_id: 'ques27',
      question_number: 27,
      question: `Does patient_name display any hand flapping/finger/flicking vocal tics, smelling/licking objects?`,
      answer: '',
      isCompulsory: true,
      type: 'radio',
      option: ['Yes', 'No'],
      isRequired: true,
      isAdditional: true,
      isAdditionalStructure: {
        parent_id: 'ques27',
        question_id: 'ques1',
        question_number: 1,
        question: 'When and Where',
        isRequired: false,
        type: 'textArea',
        answer: '',
        option: '',
        isCompulsory: true,
      },
    },
    {
      question_id: 'ques28',
      question_number: 28,
      question: `Does patient_name have any strong attachments to specific objects?`,
      answer: '',
      isCompulsory: true,
      type: 'radio',
      option: ['Yes', 'No'],
      isRequired: true,
      isAdditional: true,
      isAdditionalStructure: {
        parent_id: 'ques28',
        question_id: 'ques1',
        question_number: 1,
        question: '',
        isRequired: false,
        type: 'textArea',
        answer: '',
        option: '',
        isCompulsory: true,
      },
    },

    {
      question_id: 'ques29',
      question_number: 29,
      question: `Does patient_name show interest in the parts of objects rather than the whole object?`,
      answer: '',
      isCompulsory: true,
      type: 'radio',
      option: ['Yes', 'No'],
      isRequired: true,
      isAdditional: true,
      isAdditionalStructure: {
        parent_id: 'ques29',
        question_id: 'ques1',
        question_number: 1,
        question: '',
        isRequired: false,
        type: 'textArea',
        answer: '',
        option: '',
        isCompulsory: true,
      },
    },

    {
      question_id: 'ques30',
      question_number: 30,
      question: `Describe how patient_name copes with change to their routines and/or environment :`,
      answer: '',
      isCompulsory: true,
      type: 'textArea',
      option: '',
      isRequired: true,
      isAdditional: false,
    },

    {
      question_id: 'ques31',
      question_number: 31,
      question: `Are you aware of any routine/ rituals that patient_name must perform, such as always eating a snack in a particular order, or following the same routine every morning?`,
      answer: '',
      type: 'radio',
      option: ['Yes', 'No'],
      isCompulsory: true,
      isRequired: true,
      isAdditional: true,
      isAdditionalStructure: {
        parent_id: 'ques31',
        question_id: 'ques1',
        question_number: 1,
        question: '',
        isRequired: false,
        type: 'textArea',
        answer: '',
        option: '',
        isCompulsory: true,
      },
    },

    {
      question_id: 'ques32',
      question_number: 32,
      question: `How does patient_name cope in structured versus unstructured environments? (e.g. assembly vs playground or free play vs classroom activity)?`,
      answer: '',
      isCompulsory: true,
      type: 'textArea',
      option: '',
      isRequired: true,
      isAdditional: false,
    },
  ],

  sensory: [
    {
      question_id: 'ques33',
      question_number: 33,
      question: `Have you got concerns about how patient_name responds to noise?`,
      answer: '',
      isCompulsory: true,
      type: 'radio',
      option: ['Yes', 'No'],
      isRequired: true,
      isAdditional: true,
      isAdditionalStructure: {
        parent_id: 'ques33',
        question_id: 'ques1',
        question_number: 1,
        question: `Please describe how patient_name responds to noise (E.g. covers ears; slow to respond when you speak to them; gets easily distracted; gets distressed, makes loud noises or hums)`,
        isRequired: false,
        type: 'textArea',
        answer: '',
        option: '',
        isCompulsory: true,
      },
    },
    {
      question_id: 'ques34',
      question_number: 34,
      question: `Have you got concerns about how patient_name responds to touch?`,
      answer: '',
      isCompulsory: true,
      type: 'radio',
      option: ['Yes', 'No'],
      isRequired: true,
      isAdditional: true,
      isAdditionalStructure: {
        parent_id: 'ques34',
        question_id: 'ques1',
        question_number: 1,
        question: `Please describe how patient_name responds to touch (E.g. dislikes messy play; has difficulty touching or wearing certain materials; reacts in unexpected ways when someone touches them)`,
        isRequired: false,
        type: 'textArea',
        answer: '',
        option: '',
        isCompulsory: true,
      },
    },
    {
      question_id: 'ques35',
      question_number: 35,
      question: `Have you got concerns about how patient_name responds to movement?`,
      answer: '',
      isCompulsory: true,
      type: 'radio',
      option: ['Yes', 'No'],
      isRequired: true,
      isAdditional: true,
      isAdditionalStructure: {
        parent_id: 'ques35',
        question_id: 'ques1',
        question_number: 1,
        question: `Please describe how patient_name responds to movement (E.g. flaps hands and enjoys it; dislikes swings and slides; difficulty climbing steps; often fidgets or bounces, walks on tiptoes)`,
        isRequired: false,
        type: 'textArea',
        answer: '',
        option: '',
        isCompulsory: true,
      },
    },
    {
      question_id: 'ques36',
      question_number: 36,
      question: `Have you got concerns about how patient_name responds to taste or smell?`,
      answer: '',
      isCompulsory: true,
      type: 'radio',
      option: ['Yes', 'No'],
      isRequired: true,
      isAdditional: true,
      isAdditionalStructure: {
        parent_id: 'ques36',
        question_id: 'ques1',
        question_number: 1,
        question: `Please describe how patient_name responds to taste and smell (E.g. avoids certain tastes, textures or smells; eats a small range of food; prefers food with bold textures and flavours)`,
        isRequired: false,
        type: 'textArea',
        answer: '',
        option: '',
        isCompulsory: true,
      },
    },
    {
      question_id: 'ques37',
      question_number: 37,
      question: `Have you got concerns about how patient_name responds to light?`,
      answer: '',
      isCompulsory: true,
      type: 'radio',
      option: ['Yes', 'No'],
      isRequired: true,
      isAdditional: true,
      isAdditionalStructure: {
        parent_id: 'ques37',
        question_id: 'ques1',
        question_number: 1,
        question: `Please describe how patient_name responds to light (E.g. enjoys looking at things move, enjoys moving or flickering light, avoids or is distressed by  bright lights)`,
        isRequired: false,
        type: 'textArea',
        answer: '',
        option: '',
        isCompulsory: true,
      },
    },
    {
      question_id: 'ques38',
      question_number: 38,
      question: `Please describe any other sensory seeking behaviours that patient_name enjoys:`,
      answer: '',
      isCompulsory: false,
      type: 'textArea',
      option: '',
      isRequired: true,
      isAdditional: false,
    },
    {
      question_id: 'ques39',
      question_number: 39,
      question: `Please describe any other sensory avoiding behaviours patient_name shows:`,
      answer: '',
      isCompulsory: false,
      type: 'textArea',
      option: '',
      isRequired: true,
      isAdditional: false,
    },
  ],

  attentionActivityLevels: [
    {
      question_id: 'ques40',
      question_number: 40,
      question:
        'Is enthusiastic and keen to share their ideas before the speaker has finished asking a question.',
      answer: '',
      type: 'radio',
      option: ['Never', 'Sometimes', 'Always', 'N/A'],
      isRequired: true,
      isAdditional: false,
      isCompulsory: true,
    },
    {
      question_id: 'ques41',
      question_number: 41,
      question: 'Is overbearing and loud while playing with peers.',
      answer: '',
      type: 'radio',
      option: ['Never', 'Sometimes', 'Always', 'N/A'],
      isRequired: true,
      isAdditional: false,
      isCompulsory: true,
    },
    {
      question_id: 'ques42',
      question_number: 42,
      question: 'Takes actions without thinking of the consequences.',
      answer: '',
      type: 'radio',
      option: ['Never', 'Sometimes', 'Always', 'N/A'],
      isRequired: true,
      isCompulsory: true,
      isAdditional: false,
    },
    {
      question_id: 'ques43',
      question_number: 43,
      question: 'Acts then instantly says they didn’t mean to.',
      answer: '',
      type: 'radio',
      option: ['Never', 'Sometimes', 'Always', 'N/A'],
      isRequired: true,
      isAdditional: false,
      isCompulsory: true,
    },
    {
      question_id: 'ques44',
      question_number: 44,
      question: 'Difficulty staying on task in the class or in play.',
      answer: '',
      type: 'radio',
      option: ['Never', 'Sometimes', 'Always', 'N/A'],
      isRequired: true,
      isAdditional: false,
      isCompulsory: true,
    },
    {
      question_id: 'ques45',
      question_number: 45,
      question: 'Disturbs others when playing or working.',
      answer: '',
      type: 'radio',
      option: ['Never', 'Sometimes', 'Always', 'N/A'],
      isRequired: true,
      isAdditional: false,
      isCompulsory: true,
    },
    {
      question_id: 'ques46',
      question_number: 46,
      question: 'Has ‘careless mistakes’ or inaccuracies in work.',
      answer: '',
      type: 'radio',
      option: ['Never', 'Sometimes', 'Always', 'N/A'],
      isRequired: true,
      isAdditional: false,
      isCompulsory: true,
    },
    {
      question_id: 'ques47',
      question_number: 47,
      question: 'Gets out of their seat when not expected.',
      answer: '',
      type: 'radio',
      option: ['Never', 'Sometimes', 'Always', 'N/A'],
      isRequired: true,
      isAdditional: false,
      isCompulsory: true,
    },
    {
      question_id: 'ques48',
      question_number: 48,
      question: 'Climbs and jumps when being still is expected.',
      answer: '',
      type: 'radio',
      option: ['Never', 'Sometimes', 'Always', 'N/A'],
      isRequired: true,
      isAdditional: false,
      isCompulsory: true,
    },
    {
      question_id: 'ques49',
      question_number: 49,
      question: 'Fidgets and squirms.',
      answer: '',
      type: 'radio',
      option: ['Never', 'Sometimes', 'Always', 'N/A'],
      isRequired: true,
      isAdditional: false,
      isCompulsory: true,
    },
    {
      question_id: 'ques50',
      question_number: 50,
      question: 'Is always ‘on the go’.',
      answer: '',
      type: 'radio',
      option: ['Never', 'Sometimes', 'Always', 'N/A'],
      isRequired: true,
      isAdditional: false,
      isCompulsory: true,
    },
    {
      question_id: 'ques51',
      question_number: 51,
      question: 'Difficulty listening to teaching part of lesson/assembly.',
      answer: '',
      type: 'radio',
      option: ['Never', 'Sometimes', 'Always', 'N/A'],
      isRequired: true,
      isAdditional: false,
      isCompulsory: true,
    },
    {
      question_id: 'ques52',
      question_number: 52,
      question: 'Avoids or dislikes activities which require mental effort.',
      answer: '',
      type: 'radio',
      option: ['Never', 'Sometimes', 'Always', 'N/A'],
      isRequired: true,
      isAdditional: false,
      isCompulsory: true,
    },
    {
      question_id: 'ques53',
      question_number: 53,
      question: 'Doesn’t finish tasks.',
      answer: '',
      type: 'radio',
      option: ['Never', 'Sometimes', 'Always', 'N/A'],
      isRequired: true,
      isAdditional: false,
      isCompulsory: true,
    },
    {
      question_id: 'ques54',
      question_number: 54,
      question:
        'Finds it difficult to start tasks (even ones they could easily do).',
      answer: '',
      type: 'radio',
      option: ['Never', 'Sometimes', 'Always', 'N/A'],
      isRequired: true,
      isAdditional: false,
      isCompulsory: true,
    },
    {
      question_id: 'ques55',
      question_number: 55,
      question: 'Is forgetful during tasks.',
      answer: '',
      type: 'radio',
      option: ['Never', 'Sometimes', 'Always', 'N/A'],
      isRequired: true,
      isAdditional: false,
      isCompulsory: true,
    },
    {
      question_id: 'ques56',
      question_number: 56,
      question: 'Often loses items.',
      answer: '',
      type: 'radio',
      option: ['Never', 'Sometimes', 'Always', 'N/A'],
      isRequired: true,
      isAdditional: false,
      isCompulsory: true,
    },
    {
      question_id: 'ques57',
      question_number: 57,
      question: 'Can not get organised with equipment needed.',
      answer: '',
      type: 'radio',
      option: ['Never', 'Sometimes', 'Always', 'N/A'],
      isRequired: true,
      isAdditional: false,
      isCompulsory: true,
    },
    {
      question_id: 'ques58',
      question_number: 58,
      question: `Any comments you would like to add with respect to the attention and activity levels above?`,
      placeholderName:
        'Please add information such as zoning out, experiencing difficulties with making a choice, finding it difficult to prioritise tasks, forgetting to eat/drink',
      answer: '',
      type: 'textArea',
      option: '',
      isRequired: true,
      isAdditional: false,
      isCompulsory: false,
    },

    {
      question_id: 'ques59',
      question_number: 59,
      isHeadingLine: true,
      isHeading: 'Comment on patient_name ability to access the curriculum: ',
      question: `Reading`,
      answer: '',
      placeholderName: 'Level',
      isCompulsory: true,
      type: 'text',
      option: [],
      isRequired: true,
      isAdditional: false,
      isNested: true,
      isNestedStructure: [
        {
          parent_id: 'ques59',
          question_id: 'ques1',
          question_number: 1,
          question: 'Compared to class',
          isRequired: true,
          type: 'radio',
          answer: '',
          option: ['Below Average', 'Average', 'Above Average'],
          isCompulsory: true,
        },
        {
          parent_id: 'ques59',
          question_id: 'ques2',
          question_number: 2,
          question: '',
          isRequired: false,
          type: 'radio',
          answer: '',
          option: ['2%', '10%', '25%'],
          isCompulsory: true,
        },
      ],
    },
    {
      question_id: 'ques60',
      question_number: 60,
      isHeadingLine: false,
      isHeading: 'Comment on patient_name ability to access the curriculum: ',
      question: `Writing`,
      isCompulsory: true,
      answer: '',
      placeholderName: 'Level',
      type: 'text',
      option: [],
      isRequired: true,
      isAdditional: false,
      isNested: true,
      isNestedStructure: [
        {
          parent_id: 'ques60',
          question_id: 'ques1',
          question_number: 1,
          question: 'Compared to class',
          isRequired: true,
          type: 'radio',
          answer: '',
          isCompulsory: true,
          option: ['Below Average', 'Average', 'Above Average'],
        },
        {
          parent_id: 'ques60',
          question_id: 'ques2',
          question_number: 2,
          question: '',
          isRequired: false,
          type: 'radio',
          answer: '',
          option: ['2%', '10%', '25%'],
          isCompulsory: true,
        },
      ],
    },
    {
      question_id: 'ques61',
      question_number: 61,
      isHeadingLine: false,
      isCompulsory: true,
      isHeading: 'Comment on patient_name ability to access the curriculum: ',
      question: `Maths`,
      answer: '',
      placeholderName: 'Level',
      type: 'text',
      option: [],
      isRequired: true,
      isAdditional: false,
      isNested: true,
      isNestedStructure: [
        {
          parent_id: 'ques61',
          question_id: 'ques1',
          question_number: 1,
          question: 'Compared to class',
          isRequired: true,
          type: 'radio',
          answer: '',
          option: ['Below Average', 'Average', 'Above Average'],
          isCompulsory: true,
        },
        {
          parent_id: 'ques61',
          question_id: 'ques2',
          question_number: 2,
          question: '',
          isRequired: false,
          type: 'radio',
          answer: '',
          isCompulsory: true,
          option: ['2%', '10%', '25%'],
        },
      ],
    },

    {
      question_id: 'ques62',
      question_number: 62,
      question: `Describe patient_name ability to pay attention in a variety of learning situations.`,
      answer: '',
      isCompulsory: true,
      type: 'textArea',
      option: '',
      isRequired: true,
      isAdditional: false,
    },

    {
      question_id: 'ques63',
      question_number: 63,
      question: `Can patient_name transition from one activity to another without difficulty?`,
      answer: '',
      type: 'radio',
      isCompulsory: true,
      option: ['Yes', 'No'],
      isRequired: true,
      isAdditional: true,
      isAdditionalStructure: {
        parent_id: 'ques63',
        question_id: 'ques1',
        question_number: 1,
        question: '',
        isRequired: false,
        type: 'textArea',
        answer: '',
        option: '',
        isCompulsory: true,
      },
    },
    {
      question_id: 'ques64',
      question_number: 64,
      question: `Does patient_name have any difficulties starting or finishing tasks, being on time?`,
      answer: '',
      placeholderName: 'Comment on patient_name organisational skills ',
      isCompulsory: true,
      type: 'radio',
      option: ['Yes', 'No'],
      isRequired: true,
      isAdditional: true,
      isAdditionalStructure: {
        parent_id: 'ques64',
        question_id: 'ques1',
        question_number: 1,
        question: '',
        isRequired: false,
        type: 'textArea',
        answer: '',
        option: '',
        isCompulsory: true,
      },
    },

    {
      question_id: 'ques65',
      question_number: 65,
      question: `Does patient_name have difficulties with gross/fine motor skills (e.g. handwriting)?`,
      answer: '',
      isCompulsory: true,
      type: 'radio',
      option: ['Yes', 'No'],
      isRequired: true,
      isAdditional: true,
      isAdditionalStructure: {
        parent_id: 'ques65',
        question_id: 'ques1',
        question_number: 1,
        question: '',
        isRequired: false,
        type: 'textArea',
        answer: '',
        option: '',
        isCompulsory: true,
      },
    },
    {
      question_id: 'ques66',
      question_number: 66,
      question: `Are there any safeguarding concerns? Is patient_name known to social care (now or in the past, including family solutions)?`,
      answer: '',
      type: 'textArea',
      option: '',
      isRequired: true,
      isAdditional: false,
      isCompulsory: false,
    },
  ],

  otherRelevantInformation: [
    {
      question_id: 'ques67',
      question_number: 67,
      question: `Does patient_name have a Classroom/Teaching Assistant that provides them with additional support in Education Setting?`,
      answer: '',
      type: 'radio',
      option: ['Part time', 'Full time', 'No'],
      isRequired: true,
      isAdditional: false,
      isCompulsory: false,
    },
    {
      question_id: 'ques68',
      question_number: 68,
      question: `Has patient_name got an EHCP?`,
      answer: '',
      type: 'radio',
      option: ['Yes', 'No'],
      isRequired: true,
      isAdditional: false,
      isCompulsory: false,
    },
    {
      question_id: 'ques69',
      question_number: 69,
      question:
        'What additional support is in place (including skills training)?',
      answer: '',
      type: 'textArea',
      option: '',
      isRequired: true,
      isAdditional: false,
      isCompulsory: false,
    },

    {
      question_id: 'ques70',
      question_number: 70,
      question:
        'Is One Plan or Early Support Plan in place? (If yes, please attach a copy of the plan. If not please attach a report that details relevant support in place) ',
      answer: '',
      type: 'radio',
      option: ['Yes', 'No'],
      isCompulsory: false,
      isRequired: true,
      isAdditional: false,
      isAdditionalStructure: {
        parent_id: 'ques70',
        question_id: 'ques1',
        question_number: 1,
        question: '',
        isRequired: false,
        type: 'textArea',
        answer: '',
        option: '',
        isCompulsory: true,
      },
    },
    {
      question_id: 'ques71',
      question_number: 71,
      question: `Is patient_name known to any other support services?`,
      answer: '',
      isCompulsory: false,
      type: 'radio',
      option: ['Yes', 'No'],
      isRequired: true,
      isAdditional: true,
      isAdditionalStructure: {
        parent_id: 'ques71',
        question_id: 'ques1',
        question_number: 1,
        question: 'What other support services is patient_name known to',
        isRequired: false,
        type: 'textArea',
        answer: '',
        option: '',
        isCompulsory: true,
      },
    },
    {
      question_id: 'ques72',
      question_number: 72,
      isCompulsory: false,
      question: `Is patient_name known to Educational Psychologist?`,
      answer: '',
      type: 'radio',
      option: ['Yes', 'No'],
      isRequired: true,
      isAdditional: true,
      isAdditionalStructure: {
        parent_id: 'ques72',
        question_id: 'ques1',
        question_number: 1,
        question: '',
        isRequired: false,
        type: 'textArea',
        answer: '',
        option: '',
        isCompulsory: true,
      },
    },
    {
      question_id: 'ques73',
      question_number: 73,
      isCompulsory: true,
      question: `Date of referral?`,
      answer: `${new Date()}`,
      type: 'date',
      option: [],
      isRequired: true,
      isAdditional: false,
      isAdditionalStructure: {
        parent_id: 'ques73',
        question_id: 'ques1',
        question_number: 1,
        question: '',
        isRequired: false,
        type: 'textArea',
        answer: '',
        option: '',
        isCompulsory: true,
      },
    },
  ],
}

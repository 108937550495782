import { useDispatch, useSelector } from 'react-redux';
import { createSelector } from "reselect";
import { caseDocumentsById, viewAllReferralDetail } from '../../../redux/action';
import { useEffect, useState } from 'react';

export const useDownloadFile = ({ activateTab, type }: { activateTab: any, type: string }) => {
  const dispatch = useDispatch();
  const referralReducer = (state: any) => state?.referralReducer;
  const docListReducer = (state: any) => state?.caseDocumentReducer;
  const referralReducerData = createSelector(referralReducer, (state) => ({
    referralurl: state?.referralAllDetail,
  }));

  const caseDocuments = createSelector(docListReducer, (state) => ({
    casedocList: state?.data?.data?.caseload_documents,
  }));

  const { casedocList } = useSelector(caseDocuments);

  const { referralurl } = useSelector(referralReducerData);
  const [triggerDownload, setTriggerDownload] = useState(false);

  const handleDownload = (refId: string | null | undefined, caseloadId: string | null) => {
    if (!refId && !caseloadId) {
      console.error("No valid ID provided for download.");
      return;
    }
    if (caseloadId) {
      dispatch(caseDocumentsById(caseloadId,"navigate",true));
    } else if (refId) {
      dispatch(viewAllReferralDetail({ ReferralId: refId }));
    }
    setTriggerDownload(true);
  };

  useEffect(() => {
    if (triggerDownload && (referralurl?.url || casedocList?.length)) {
  
      const isAvailable = casedocList?.filter(
        (item: { uploadType: string }) => item.uploadType === type
      );
      const downloadUrl = 
        activateTab.current === 0 && referralurl?.url || 
        (activateTab.current === 1 || activateTab === 1) && isAvailable[0]?.url;
      if (downloadUrl) {
        const a = document.createElement('a');
        a.href = downloadUrl;
        a.download = ''; 
        a.click();
        setTriggerDownload(false);
      }
    }
  }, [referralurl, casedocList, activateTab]);
  

  return {
    handleDownload,
  };
};

import React, { useEffect, useLayoutEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { createSelector } from "reselect";
import {
  Container,
  Grid,
  Box,
  InputAdornment,
  IconButton,
  Typography,
  TextField,
  Button,
} from "@mui/material";
// import './style.css'
import css from "./index.module.scss";
import Logo from "../../../assets/Images/logo.svg";
import nodomain from "../../../assets/Images/nodomain.webp";
import Provide from "../../../assets/Images/provide_logo.png";
import { Field, Formik, useFormik, Form } from "formik";
import HealthAndSafetyIcon from "@mui/icons-material/HealthAndSafety";
import * as Yup from "yup";
import { ForgotPasswordFormValues, LoginInterface } from "../interface";
import {
  VerifyDomain,
  clearDomain,
  forgotPassword,
  localDomain,
  login,
} from "../../../redux/action";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { style } from "./style";
import constant from "../../../constants/constant";
import CircularProgress from "@mui/material/CircularProgress";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import RadioGroup from "@mui/material/RadioGroup";
import PersonIcon from "@mui/icons-material/Person";

const authReducer = (state: any) => state?.Login_Response;
const Login = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [currentScope, setCurrentScope] = useState(constant.SCOPE.CLIENT_ADMIN);
  const [forgotPsdState, SetForgotPsdState] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const hostname = window?.location?.hostname;
  const currentURL = window.location.href;
  const parts = currentURL?.split("//")?.[1]?.split(".mycarebridge")?.[0];
  const typeStg = parts?.endsWith(".stg") ? parts?.split(".stg") : [parts];
  const shortName = typeStg?.[0];

  const authData = createSelector(authReducer, (state) => ({
    domainResponse: state.domainResponse,
  }));

  const { domainResponse } = useSelector(authData);

  const validation = useFormik<LoginInterface>({
    enableReinitialize: true,
    initialValues: {
      username: "",
      password: "",
      grant_type: "password",
      // scope: location.pathname === "/clinician/login" ? constant.SCOPE.CLINICIAN : constant.SCOPE.CLIENT_ADMIN
      scope: currentScope,
    },
    validationSchema: Yup.object({
      username: Yup.string().required("Please Enter Email Address"),
      password: Yup.string().required("Please Enter Password"),
    }),
    onSubmit: (values: LoginInterface) => {
      const transformedValues = {
        ...values,
        username: values.username.toLowerCase(),
      };
      dispatch(login(transformedValues, navigate));
    },
  });

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  useLayoutEffect(() => {
    if (hostname === "localhost" || hostname === "192.168.1.4") {
      dispatch(localDomain());
    } else {
      dispatch(clearDomain());
      if (shortName === "privacy") {
        navigate("/privacy-notice");
      } else {
        dispatch(VerifyDomain({ short_name: shortName }));
      }
    }
  }, []);

  const handleSubmit = async (values: ForgotPasswordFormValues) => {
    dispatch(forgotPassword(values, navigate));
    SetForgotPsdState(false);
  };

  useEffect(() => {
    window.history.pushState(null, "", window.location.href);
    const handlePopState = (event: PopStateEvent) => {
      window.history.pushState(null, "", window.location.href);
    };
    window.addEventListener("popstate", handlePopState);

    return () => {
      window.removeEventListener("popstate", handlePopState);
    };
  }, []);

  return (
    <div className={css.loginLayout}>
      {domainResponse?.validate_domain === "" ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginTop: "10rem",
          }}
        >
          <CircularProgress />
        </Box>
      ) : domainResponse?.validate_domain === true ? (
        <Box
          className={css.wrapperLayout}
          sx={{
            background: "#6A238210",
            height: "100vh",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <Box
            className={css.loginSection}
            sx={{ height: "calc(100vh - 50px)", display: "flex" }}
          >
            <Grid
              container
              columns={12}
              spacing={4}
              sx={{ alignItems: "center" }}
            >
              <Grid item sx={{ margin: "auto" }}>
                <Grid
                  className={css.login_card_text}
                  sx={{
                    minWidth: {
                      lg: "500px",
                      sm: "500px",
                      md: "500px",
                      xl: "500px",
                      xs: "300px",
                    },
                    maxWidth: {
                      lg: "500px",
                      sm: "500px",
                      md: "500px",
                      xl: "500px",
                      xs: "360px",
                    },
                  }}
                >
                  <Grid item xs={12} sx={{ margin: "auto" }}>
                    <Box
                      component="img"
                      src={Logo}
                      alt="Logo"
                      sx={{
                        maxWidth: {
                          lg: "200px",
                          sm: "250px",
                          md: "250px",
                          xl: "250px",
                          xs: "200px",
                        },
                        margin: "0rem auto 1rem",
                        display: "flex",
                      }}
                    />
                  </Grid>
                  {!forgotPsdState ? (
                    <>
                      <form onSubmit={validation.handleSubmit}>
                        <Grid sx={{}}>
                          <Grid item md={12} xs={12}>
                            <RadioGroup
                              aria-label="Your plan"
                              name="people"
                              defaultValue="Individual"
                            >
                              <List
                                sx={{
                                  display: "flex",
                                  marginBottom: "1rem",
                                  height: "70px",
                                }}
                              >
                                <ListItem
                                  sx={{
                                    padding: {
                                      xs: "0",
                                    },
                                  }}
                                  className={
                                    currentScope === constant.SCOPE.CLIENT_ADMIN
                                      ? css.isActive
                                      : css.linkColor
                                  }
                                >
                                  <ListItemButton
                                     sx={{
                                      fontSize: {
                                      lg: "16px",
                                      sm: "16px",
                                      md: "16px",
                                      xl: "16px",
                                      xs: "14px",
                                      },
                                      padding: {
                                        xs: "0",
                                      },
                                      height:'100%'
                                    }}
                                    onClick={() =>
                                      setCurrentScope(
                                        constant.SCOPE.CLIENT_ADMIN
                                      )
                                    }
                                  >
                                    <PersonIcon />
                                    Admin Login
                                  </ListItemButton>
                                </ListItem>
                                <ListItem
                                  sx={{
                                    padding: { 
                                      xs: "0",
                                    },
                                  }}
                                  className={
                                    currentScope === constant.SCOPE.CLINICIAN
                                      ? css.isActive
                                      : css.linkColor
                                  }
                                >
                                  <ListItemButton
                                    sx={{
                                      fontSize: {
                                        lg: "16px",
                                        sm: "16px",
                                        md: "16px",
                                        xl: "16px",
                                        xs: "14px",
                                      },
                                      padding: {
                                        xs: "0",
                                      },
                                      height:'100%'
                                    }}
                                    onClick={() =>
                                      setCurrentScope(constant.SCOPE.CLINICIAN)
                                    }
                                  >
                                    <HealthAndSafetyIcon />
                                    Clinician Login
                                  </ListItemButton>
                                </ListItem>
                              </List>
                            </RadioGroup>
                          </Grid>
                          <Grid item md={12} xs={12} mb={2}>
                            <Box>
                              <InputLabel
                                className={css.text_start}
                                sx={{
                                  fontFamily:'poppins',
                                  fontSize: {
                                    xs: "14px",
                                    lg:'16px',
                                    md:'16px',
                                    sm:'16px'
                                  },
                                }}
                                htmlFor="outlined-adornment-password"
                              >
                                Email
                              </InputLabel>

                              <TextField
                                fullWidth
                                placeholder="Enter email"
                                sx={{ width: "100%",'input':{
                                  fontSize: {
                                    xs: "14px",
                                    lg:'16px',
                                    md:'16px',
                                    sm:'16px'
                                  }
                                }}}
                                {...validation.getFieldProps("username")}
                                error={
                                  validation.touched.username &&
                                  Boolean(validation.errors.username)
                                }
                                helperText={
                                  validation.touched.username &&
                                  validation.errors.username
                                }
                              />
                            </Box>
                          </Grid>

                          <Grid item xs={12} sm={12}>
                            <Box className={css.mt_25}>
                              <InputLabel
                               sx={{
                                fontFamily:'poppins',
                                fontSize: {
                                  xs: "14px",
                                  lg:'16px',
                                  md:'16px',
                                  sm:'16px'
                                },
                              }}
                                className={css.text_start}
                                htmlFor="outlined-adornment-password"
                              >
                                Password
                              </InputLabel>
                              <FormControl
                                sx={{ width: "100%",'input':{
                                  fontSize: {
                                    xs: "14px",
                                    lg:'16px',
                                    md:'16px',
                                    sm:'16px'
                                  }
                                }}}
                                variant="outlined"
                              >
                                <OutlinedInput
                                  id="outlined-adornment-password"
                                  type={showPassword ? "text" : "password"}
                                  placeholder="Enter password"
                                  endAdornment={
                                    <InputAdornment position="end">
                                      <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={handleClickShowPassword}
                                        onMouseDown={handleMouseDownPassword}
                                        edge="end"
                                        className={css.eye_icon_bg_color}
                                      >
                                        {showPassword ? (
                                          <VisibilityOff />
                                        ) : (
                                          <Visibility />
                                        )}
                                      </IconButton>
                                    </InputAdornment>
                                  }
                                  {...validation.getFieldProps("password")}
                                  error={
                                    validation.touched.password &&
                                    Boolean(validation.errors.password)
                                  }
                                />
                                <Typography sx={style.error_text}>
                                  {validation.touched.password &&
                                    validation.errors.password &&
                                    validation.errors.password}
                                </Typography>
                              </FormControl>
                            </Box>
                          </Grid>
                          <Typography
                            textAlign="end"
                            style={{ cursor: "pointer" }}
                            my={2}
                            onClick={() => SetForgotPsdState(true)}
                          >
                            Forgot Password ?
                          </Typography>

                          <Button
                            type="submit"
                            variant="contained"
                            sx={{ width: "100%", height: "55px" }}
                            className={css.mt_25}
                          >
                            Login
                          </Button>
                          <Grid
                            item
                            xs={12}
                            sm={12}
                            justifyContent="center"
                            display="flex"
                          >
                            <Box
                              component={"img"}
                              src={Provide}
                              alt="Logo"
                              sx={{
                                margin: {
                                  lg:"1rem auto 0rem",
                                  xs:"1rem auto 0rem",
                                  xl:'1rem auto 0rem'
                                },
                                minWidth: "120px",
                                maxWidth: "120px",
                              }}
                            />
                          </Grid>
                        </Grid>
                      </form>
                    </>
                  ) : (
                    <>
                      <Typography
                        variant="h4"
                        sx={{
                          fontSize: "20px",
                          fontWeight: "600",
                          marginY: "1rem",
                          textAlign: "center",
                        }}
                      >
                        Forgot Password
                      </Typography>
                      <Formik
                        initialValues={{ email: "" }}
                        onSubmit={handleSubmit}
                      >
                        <Form>
                          <InputLabel
                            className={css.text_start}
                            htmlFor="outlined-adornment-password"
                            sx={{
                              fontFamily:'poppins',
                              fontSize: {
                                xs: "14px",
                                lg:'16px',
                                md:'16px',
                                sm:'16px'
                              },
                            }}
                          >
                            Email
                          </InputLabel>
                          <Field
                           sx={{ width: "100%",'input':{
                            fontSize: {
                              xs: "14px",
                              lg:'16px',
                              md:'16px',
                              sm:'16px'
                            }
                          }}}
                            as={TextField}
                            placeholder="Enter email"
                            name="email"
                            fullWidth
                            required
                          />
                          <Grid textAlign={"center"} marginTop={"10px"}>
                            <Typography
                              textAlign="end"
                              style={{ cursor: "pointer" }}
                              my={2}
                              onClick={() => SetForgotPsdState(false)}
                            >
                              {" "}
                              Back to Login
                            </Typography>

                            <Button
                              type="submit"
                              variant="contained"
                              sx={{ width: "100%", height: "55px" }}
                              className={css.mt_25}
                            >
                              Reset Password
                            </Button>
                            <Box
                              component={"img"}
                              src={Provide}
                              alt="Logo"
                              sx={{
                                margin: {
                                  lg:"1rem auto 0rem",
                                  xs:"1rem auto 0rem",
                                  xl:'1rem auto 0rem'
                                },
                                minWidth: "120px",
                                maxWidth: "120px",
                              }}
                            />
                          </Grid>
                        </Form>
                      </Formik>
                    </>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Box>
          <Box sx={{ background: "#6A238220", width: "100%" }}>
            <Container maxWidth="xl">
              <Box
                sx={{
                  height: "50px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  flexDirection: {
                    xs: "column",
                    lg:'row',
                    md:'row',
                    sm:'row'
                  }
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    gap: "5px",
                  }}
                >
                  <Typography>
                    <Link
                      to={"/"}
                      className={css.condition}
                      style={{
                        color: "#222",
                        fontWeight: "500",
                        cursor: "pointer",
                      }}
                    >
                      Terms & Condition
                    </Link>
                  </Typography>{" "}
                  ||
                  <Typography>
                    <Link
                      target="_blank"
                      rel="noopener noreferrer"
                      to={"https://mycarebridge.co.uk/privacy-notice"}
                      className={css.condition}
                      style={{
                        color: "#222",
                        fontWeight: "500",
                        cursor: "pointer",
                      }}
                    >
                      Privacy Notice
                    </Link>
                  </Typography>
                </Box>

                <Typography sx={{ color: "#222", fontWeight: "500" }}>
                  2024 Provide Digital LTD all rights reserved
                </Typography>
              </Box>
            </Container>
          </Box>
        </Box>
      ) : (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginTop: "10rem",
          }}
        >
          <Grid className="nodocumentImg">
            <img src={nodomain} alt="No-Document" style={{ width: "100%" }} />
            <Typography variant="h5">Invalid Domain</Typography>
          </Grid>
        </Box>
      )}
    </div>
  );
};
export default Login;

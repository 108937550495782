import { call, cancelled, delay, put, takeLatest } from "redux-saga/effects";
import {
  ADD_TASK,
  ADD_TASK_FAILURE,
  ADD_TASK_SUCCESS,
  GET_SCHOOL_REPORT,
  GET_PARENT_REPORT,
  RESPONSE_PARENT_REPORT,
  RESPONSE_SCHOOL_REPORT,
  RESPONSE_CASHUSER,
  GET_CASELOAD_BY_ID,
  CASELOAD_LISTING,
  CASE_DOCUMENTS_BY_ID_FAILURE,
  CASE_DOCUMENTS_BY_ID_REQUEST,
  CASE_DOCUMENTS_BY_ID_SUCCESS,
  CREATE_CASELOAD,
  RESPONSE_TASK,
  TASK_LISTING,
  UPLOAD_FILE,
  CASEUSER_LISTING,
  RESPONSE_CASELOAD_BY_ID,
  SUBMIT_TASK,
  CREATE_CONVERSATIONS,
  CREATE_CONVERSATIONS_SUCCESS,
  CREATE_CONVERSATIONS_FAILURE,
  SENT_MESSAGE,
  CHATMSG_LISTING,
  RESPONSE_CHATMSG,
  RESPONSE_CHATCONVERTSATION,
  CHATCONVERTSATION_LISTING,
  UPDATE_TASK,
  UPDATE_DETAILS_BY_CASELOAD,
  CLINICAL_REVIEW,
  CASELOAD_FULL_LISTING,
  RESPONSE_CASELOAD,
  RESPONSE_FULL_CASELOAD,
  RED_FLAG_LIST,
  GET_RED_FLAG_LIST,
  DELETE_ADDITIONAL_PARENT,
  DELETE_UPLOAD_DOC,
  DELETE_TASK,
  ADD_SYSTEM,
  ADD_REDFLAG_SYSTEM,
  DOWNLOAD_FILE,
} from "./actionType";
import { AxiosResponse } from "axios";
import { saveAs } from 'file-saver';
import service from "../../api/services";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { setLoading, uploadFileFailure, uploadFileSuccess } from "./action";
import {
  RESOURCES_DOCUMENT,
  RES_RESOURCES_DOCUMENT,
} from "../resources/actionType";

function* createCaseload({
  data,
  navigate,
}: any): Generator<any, AxiosResponse<any>, any> {
  try {
    const response: AxiosResponse<any> = yield service.Caseload.add(data);
    if (response.status === 201 || response.status === 200) {
      toast.success(response?.data?.message);
      navigate("/manage-caseload/active");
    } else {
      toast.error(response?.data?.message);
    }
    return response;
  } catch (error: any) {
    toast.error(error?.response?.data?.message);
    return error;
  }
}

function* getCaseloadDetailById({
  id,
}: any): Generator<any, AxiosResponse<any>, any> {
  try {
    const response: AxiosResponse<any> = yield service.Caseload.getDetailByID(
      id
    );
    if (response.status === 201 || response.status === 200) {
      yield put({ type: RESPONSE_CASELOAD_BY_ID, payload: response });
    } else {
      toast.error(response?.data?.message);
    }
    return response;
  } catch (error: any) {
    toast.error(error?.response?.data?.message);
    return error;
  }
}

function* getParentReportById({
  params,
}: any): Generator<any, AxiosResponse<any>, any> {
  try {
    const response: AxiosResponse<any> = yield service.Caseload.getParentReport(
      params
    );
    if (response.status === 201 || response.status === 200) {
      yield put({
        type: RESPONSE_PARENT_REPORT,
        payload: response?.data?.data?.parentReport,
      });
    } else {
      toast.error(response?.data?.message);
    }
    return response;
  } catch (error: any) {
    toast.error(error?.response?.data?.message);
    return error;
  }
}

function* getSchoolReportById({
  params,
}: any): Generator<any, AxiosResponse<any>, any> {
  try {
    const response: AxiosResponse<any> = yield service.Caseload.getSchoolReport(
      params
    );
    if (response.status === 201 || response.status === 200) {
      yield put({
        type: RESPONSE_SCHOOL_REPORT,
        payload: response?.data?.data?.schoolReport,
      });
    } else {
      toast.error(response?.data?.message);
    }
    return response;
  } catch (error: any) {
    toast.error(error?.response?.data?.message);
    return error;
  }
}

function* getCaseloadList({
  headers,
  type,
}: any): Generator<any, AxiosResponse<any>, any> {
  const controller = new AbortController();
  try {
    const response: any = yield service.Caseload.view(headers);

    if (response.status === 201 || response.status === 200) {
      yield put(
        type === CASELOAD_LISTING
          ? { type: RESPONSE_CASELOAD, payload: response?.data }
          : { type: RESPONSE_FULL_CASELOAD, payload: response?.data }
      );
    } else {
      toast.error(response?.data?.message);
    }
    return response;
  } catch (error: any) {
    toast.error(error?.response?.data?.message);
    return error;
  } finally {
    if (yield cancelled()) {
      controller.abort();
    }
  }
}


function* getDownload({ params }: any): Generator<any, AxiosResponse<any>, any> {
  const controller = new AbortController();
  try {
    const response: any = yield service.Upload_Documents.downloadFile(params, {
      signal: controller.signal,
      responseType: 'arraybuffer'
    });

    if (response.status === 201 || response.status === 200) {
      const fileData = response.data; 
      const filename = response.headers['filename'];
      const blob = new Blob([fileData], { type: 'application/pdf' });
      saveAs(blob, filename);
    } else {
      toast.error(response?.data?.message || 'Failed to download file');
    }
    return response;
  } catch (error: any) {
    toast.error(error?.response?.data?.message || 'An error occurred during the download.');
    return error;
  } finally {
    if (yield cancelled()) {
      controller.abort();
    }
  }
}


function* createTask({
  id,
  data,
}: any): Generator<any, AxiosResponse<any>, any> {
  try {
    const response: AxiosResponse<any> = yield service.Task_Management.add(
      data
    );
    if (response.status === 201 || response.status === 200) {
      toast.success(response?.data?.message);
      yield put({ type: ADD_TASK_SUCCESS, payload: response.data });
      let params = {
        page: 1,
        search: "",
        limit: 10,
        caseload_id: id,
      };
      yield call(getTaskList, { params });
      yield call(getCaseloadDetailById, { id });
    } else {
      toast.error(response?.data?.message);
    }
    return response;
  } catch (error: any) {
    toast.error(error?.response?.data?.message);
    yield put({ type: ADD_TASK_FAILURE, payload: error });
    return error;
  }
}

function* updateTask({
  id,
  data,
}: any): Generator<any, AxiosResponse<any>, any> {
  try {
    const response: AxiosResponse<any> = yield service.Task_Management.update(
      id,
      data
    );
    if (response.status === 201 || response.status === 200) {
      toast.success(response?.data?.message);
      yield put({ type: ADD_TASK_SUCCESS, payload: response.data });
      let params = {
        page: 1,
        search: "",
        limit: 10,
        caseload_id: data.caseload_id,
      };
      yield call(getTaskList, { params });
    } else {
      toast.error(response?.data?.message);
    }
    return response;
  } catch (error: any) {
    toast.error(error?.response?.data?.message);
    yield put({ type: ADD_TASK_FAILURE, payload: error });
    return error;
  }
}

function* updateDetailsByCaseload({
  id,
  data,
}: any): Generator<any, AxiosResponse<any>, any> {
  try {
    const response: AxiosResponse<any> = yield service.Caseload.update(
      id,
      data
    );
    if (response.status === 201 || response.status === 200) {
      toast.success(response?.data?.message);
      yield put({ type: ADD_TASK_SUCCESS, payload: response.data });
      yield call(getCaseloadDetailById, { id });
    } else {
      toast.error(response?.data?.message);
    }
    return response;
  } catch (error: any) {
    toast.error(error?.response?.data?.message);
    yield put({ type: ADD_TASK_FAILURE, payload: error });
    return error;
  }
}

function* addSystemByCaseload({
  id,
  data,
}: any): Generator<any, AxiosResponse<any>, any> {
  try {
    const response: AxiosResponse<any> = yield service.Caseload.addSystemDate(
      id,
      data
    );
    if (response.status === 201 || response.status === 200) {
      toast.success(response?.data?.message);
    } else {
      toast.error(response?.data?.message);
    }
    return response;
  } catch (error: any) {
    toast.error(error?.response?.data?.message);
    yield put({ type: ADD_TASK_FAILURE, payload: error });
    return error;
  }
}

function* addSystemByRedFlag({
  id,
  data,
}: any): Generator<any, AxiosResponse<any>, any> {
  try {
    const response: AxiosResponse<any> = yield service.Caseload.redReferralsystem(
      id,
      data
    );
    if (response.status === 201 || response.status === 200) {
      toast.success(response?.data?.message);
    } else {
      toast.error(response?.data?.message);
    }
    return response;
  } catch (error: any) {
    toast.error(error?.response?.data?.message);
    yield put({ type: ADD_TASK_FAILURE, payload: error });
    return error;
  }
}




function* submitTask({
  id,
  data,
  caseload_id,
}: any): Generator<any, AxiosResponse<any>, any> {
  try {
    const response: AxiosResponse<any> = yield service.Task_Management.postById(
      id,
      data
    );
    if (response.status === 201 || response.status === 200) {
      toast.success(response?.data?.message);
      let params = {
        page: 1,
        search: "",
        limit: 10,
        caseload_id: caseload_id,
      };
      yield call(getTaskList, { params });
    } else {
      toast.error(response?.data?.message);
    }
    return response;
  } catch (error: any) {
    toast.error(error?.response?.data?.message);
    return error;
  }
}

function* submitClincialReview({
  id,
  data,
  navigate
}: any): Generator<any, AxiosResponse<any>, any> {
  try {
    const response: AxiosResponse<any> =
      yield service.Caseload.postClinicalReview(id, data);
    if (response.status === 201 || response.status === 200) {
      toast.success(response?.data?.message);
      navigate("/manage-caseload/closed");
      yield delay(1000);
      yield call(getCaseloadDetailById, { id });
      yield call(getUploadList, { id });

    } else {
      toast.error(response?.data?.message);
    }
    return response;
  } catch (error: any) {
    toast.error(error?.response?.data?.message);
    return error;
  }
}

function* submitConversations({
  id,
  data,
}: any): Generator<any, AxiosResponse<any>, any> {
  try {
    const response: AxiosResponse<any> = yield service.Chat_Management.add(
      data
    );
    if (response.status === 201 || response.status === 200) {
      toast.success(response?.data?.message);
      yield put({ type: CREATE_CONVERSATIONS_SUCCESS, payload: response.data });
      yield call(getChatConversation, { id });
    } else {
      toast.error(response?.data?.message);
    }
    return response;
  } catch (error: any) {
    toast.error(error?.response?.data?.message);
    yield put({ type: CREATE_CONVERSATIONS_FAILURE, payload: error });
    return error;
  }
}
function* sendMessagefun({
  data,
}: any): Generator<any, AxiosResponse<any>, any> {
  try {
    const response: AxiosResponse<any> = yield service.Chat_Management.sentmsg(
      data
    );

    if (response.status === 201 || response.status === 200) {
      const conversationID = data?.conversationId;
      if (conversationID) {
        yield call(getChatMessages, { id: conversationID });
      }
    } else {
      toast.error(response?.data?.message);
    }

    return response;
  } catch (error: any) {
    toast.error(error?.response?.data?.message);
    return error;
  }
}

function* getChatMessages({
  id,
  loading,
}: any): Generator<any, AxiosResponse<any>, any> {
  yield put(setLoading(true));
  try {
    const response: any = yield service.Chat_Management.getmsg(id);
    if (response.status === 201 || response.status === 200) {
      yield put({ type: RESPONSE_CHATMSG, payload: response.data?.data });
      yield put(setLoading(false));
    } else {
      toast.error(response?.data?.message);
    }
    return response;
  } catch (error: any) {
    toast.error(error?.response?.data?.message);
    return error;
  }
}

function* getChatConversation({
  id,
}: any): Generator<any, AxiosResponse<any>, any> {
  try {
    const response: any = yield service.Chat_Management.getmsgConversation(id);
    if (response.status === 201 || response.status === 200) {
      yield put({ type: RESPONSE_CHATCONVERTSATION, payload: response?.data });
    } else {
      toast.error(response?.data?.message);
    }
    return response;
  } catch (error: any) {
    toast.error(error?.response?.data?.message);
    return error;
  }
}

function* getTaskList({
  params,
}: any): Generator<any, AxiosResponse<any>, any> {
  try {
    const response: any = yield service.Task_Management.view(params);

    if (response.status === 201 || response.status === 200) {
      yield put({ type: RESPONSE_TASK, payload: response?.data });
      if (params.caseload_id) {
        const id = params.caseload_id;
        yield call(getCaseloadDetailById, { id });
      }
    } else {
      toast.error(response?.data?.message);
    }
    return response;
  } catch (error: any) {
    toast.error(error?.response?.data?.message);
    return error;
  }
}

function* getCaseUserList({
  id,
}: any): Generator<any, AxiosResponse<any>, any> {
  try {
    const response: any = yield service.Task_Management.getById(id);
    if (response.status === 201 || response.status === 200) {
      yield put({ type: RESPONSE_CASHUSER, payload: response.data?.data });
    } else {
      toast.error(response?.data?.message);
    }
    return response;
  } catch (error: any) {
    toast.error(error?.response?.data?.message);
    return error;
  }
}

function* uploadFileSaga({
  data,
  navigate,
}: any): Generator<any, AxiosResponse<any>, any> {
  try {
    const response: AxiosResponse<any> = yield service.Upload_Documents.add(
      data
    );
    if (response.status === 201 || response.status === 200) {
      yield put(uploadFileSuccess());
      const caseloadId = response?.data?.caseload_id;
      if (caseloadId) {
        yield getUploadList({ id: caseloadId, navigate });
      } else {
        let params = {
          page: 1,
          search: "",
          limit: 10,
        };
        yield getResourcesDoc({ params });
      }

      toast.success(
        `${response?.data?.document_type} Uploaded Successfully...`,
        {
          style: {
            textTransform: "capitalize",
          },
        }
      );
    } else {
      toast.error(response?.data?.message);
    }
    return response;
  } catch (error: any) {
    yield put(uploadFileFailure(error));
    toast.error(error?.response?.data?.message);
    return error;
  }
}

function* deleteUploaddoc({
  id,
  acceptRefID,
  uploadType,
}: any): Generator<any, AxiosResponse<any>, any> {
  try {
    const response: any = yield service.Upload_Documents.deleteById(id);
    if (response.status === 201 || response.status === 200) {
      toast.success(`${uploadType} Deleted Successfully...`, {
        style: {
          textTransform: "capitalize",
        },
      });
      if (acceptRefID) {
        yield getUploadList({ id: acceptRefID, uploadType });
      } else {
        let params = {
          page: 1,
          search: "",
          limit: 10,
        };
        yield getResourcesDoc({ params });
      }
    } else {
      toast.error(response?.data?.message);
    }
    return response;
  } catch (error: any) {
    toast.error(error?.response?.data?.message);
    return error;
  }
}

function* getResourcesDoc({
  params,
}: any): Generator<any, AxiosResponse<any>, any> {
  try {
    const response: any = yield service.Resources_Management.getDocById(params);

    if (response.status === 201 || response.status === 200) {
      yield put({ type: RES_RESOURCES_DOCUMENT, payload: response?.data });
    }
    return response;
  } catch (error: any) {
    return error;
  }
}

function* getUploadList({
  id,
  navigate,
  download
}: any): Generator<any, AxiosResponse<any>, any> {
  try {
    const response: AxiosResponse<any> = yield service.Upload_Documents.getById(
      id,
      download
    );

    if (response.status === 200) {
      yield put({ type: CASE_DOCUMENTS_BY_ID_SUCCESS, payload: response.data });
    } else {
      toast.error(response?.data?.message);
    }
    return response;
  } catch (error: any) {
    yield put({ type: CASE_DOCUMENTS_BY_ID_FAILURE, payload: error });
    toast.error(error?.response?.data?.message);
    return error;
  }
}

function* deleteAdditonalParent({
  id,
  userId,
}: any): Generator<any, AxiosResponse<any>, any> {
  try {
    const response: any = yield service.Caseload.deleteAdditionalParent(
      id,
      userId
    );
    if (response.status === 201 || response.status === 200) {
      toast.success(response?.data?.message);
      yield call(getCaseloadDetailById, { id });
    } else {
      toast.error(response?.data?.message);
    }
    return response;
  } catch (error: any) {
    toast.error(error?.response?.data?.message);
    return error;
  }
}

function* deleteTaskbyId({
  id,
  caseloadId,
}: any): Generator<any, AxiosResponse<any>, any> {
  try {
    const response: any = yield service.Task_Management.delete(id);
    if (response.status === 201 || response.status === 200) {
      toast.success(response?.data?.message);
      let params = {
        page: 1,
        search: "",
        limit: 10,
        caseload_id: caseloadId,
      };
      yield call(getTaskList, { params });
    } else {
      toast.error(response?.data?.message);
    }
    return response;
  } catch (error: any) {
    toast.error(error?.response?.data?.message);
    return error;
  }
}

function* getRedFlagList({
  params,
}: any): Generator<any, AxiosResponse<any>, any> {
  try {
    const response: any = yield service.Caseload.getRedFlag(params);
    if (response.status === 201 || response.status === 200) {
      yield put({ type: RED_FLAG_LIST, payload: response.data?.data });
    } else {
      toast.error(response?.data?.message);
    }
    return response;
  } catch (error: any) {
    toast.error(error?.response?.data?.message);
    return error;
  }
}

function* referralSaga() {
  yield takeLatest(DELETE_TASK, deleteTaskbyId);
  yield takeLatest(CASELOAD_LISTING, (action) =>
    getCaseloadList({ ...action, type: CASELOAD_LISTING })
  );
  yield takeLatest(CASELOAD_FULL_LISTING, (action) =>
    getCaseloadList({ ...action, type: CASELOAD_FULL_LISTING })
  );

  yield takeLatest(DOWNLOAD_FILE, getDownload);
  yield takeLatest(CREATE_CASELOAD, createCaseload);
  yield takeLatest(ADD_SYSTEM, addSystemByCaseload);
  yield takeLatest(ADD_REDFLAG_SYSTEM, addSystemByRedFlag);
  yield takeLatest(SUBMIT_TASK, submitTask);
  yield takeLatest(UPLOAD_FILE, uploadFileSaga);
  yield takeLatest(CASE_DOCUMENTS_BY_ID_REQUEST, getUploadList);
  yield takeLatest(ADD_TASK, createTask);
  yield takeLatest(UPDATE_TASK, updateTask);
  yield takeLatest(TASK_LISTING, getTaskList);
  yield takeLatest(CASEUSER_LISTING, getCaseUserList);
  yield takeLatest(CHATMSG_LISTING, getChatMessages);
  yield takeLatest(CHATCONVERTSATION_LISTING, getChatConversation);
  yield takeLatest(GET_CASELOAD_BY_ID, getCaseloadDetailById);
  yield takeLatest(GET_PARENT_REPORT, getParentReportById);
  yield takeLatest(GET_SCHOOL_REPORT, getSchoolReportById);
  yield takeLatest(CREATE_CONVERSATIONS, submitConversations);
  yield takeLatest(SENT_MESSAGE, sendMessagefun);
  yield takeLatest(UPDATE_DETAILS_BY_CASELOAD, updateDetailsByCaseload);
  yield takeLatest(CLINICAL_REVIEW, submitClincialReview);
  yield takeLatest(GET_RED_FLAG_LIST, getRedFlagList);
  yield takeLatest(RESOURCES_DOCUMENT, getResourcesDoc);
  yield takeLatest(DELETE_ADDITIONAL_PARENT, deleteAdditonalParent);
  yield takeLatest(DELETE_UPLOAD_DOC, deleteUploaddoc);
}

export default referralSaga;

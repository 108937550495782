const UploadDocuments = {
    add: () => 'upload',
    getById: (id: string, download?: boolean) => {
      let url = `caseload/${id}/documents`;
      if (download === true) {
          url += `?download=true`;
      }
      return url;
  },
    deleteById: (id:string) => `upload/${id}`,
    downloadFile: () => `reports/download`
  }
  export default UploadDocuments
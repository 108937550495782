import React, { useEffect, useState } from 'react';
import {
  Grid,
} from '@mui/material';
import classes from "./index.module.scss"
import CommonField from './CommonField';
const Behavior = ({ questions, setQuestionData, isError, setIsError, setIsDisableContinue, patientName }: any) => {

  let QuestionNumber = questions.personalDetail.length + questions.communication.length +
    questions.socialInteraction.length + questions.creativityImagination.length

  const handleAnswerChange = (index: number, value: any, key: string, updatedQuestion: string, updatedAdditionalInfo: string, updatedAdditionalQuestion: string, updatedIsHeading: string) => {
    let tempQuestions = { ...questions };
    let tempBehavior = [...tempQuestions.behaviour];
    tempBehavior[index].answer = value;

    const question_id = tempBehavior[index].question_id;

    if (tempBehavior[index]) {
      if (tempBehavior[index].question) {
        tempBehavior[index].question = updatedQuestion
      }
      if (tempBehavior[index].additional_info) {
        tempBehavior[index].additional_info = updatedAdditionalInfo
      }
      if (tempBehavior[index].isAdditionalStructure && tempBehavior[index].isAdditionalStructure.question) {
        tempBehavior[index].isAdditionalStructure.question = updatedAdditionalQuestion
      }
    }
    if (["ques26","ques27", "ques28", "ques29", "ques30", "ques31"].includes(question_id) && value === 'No') {
      tempBehavior[index].isAdditionalStructure.isRequired = false;
      tempBehavior[index].isAdditionalStructure.answer = '';
    } else if (["ques26","ques27", "ques28", "ques29", "ques30", "ques31"].includes(question_id) && value === 'Yes') {
      tempBehavior[index].isAdditionalStructure.isRequired = true;
      tempBehavior[index].isAdditionalStructure.answer = '';
    }

    setQuestionData({ ...tempQuestions, behaviour: tempBehavior });
  };

  const handleAdditionalChange = (index: number, value: any) => {
    let tempQuestions = { ...questions };
    let tempOtherInformation = [...tempQuestions.behaviour];
    tempOtherInformation[index].isAdditionalStructure.answer = value;
    setQuestionData({ ...tempQuestions, behaviour: tempOtherInformation });
  }


  useEffect(() => {
    setIsError(false)
  }, [])
  return (
    <>
      <Grid container direction="column" spacing={2} className={classes.Report}>

        {questions?.behaviour && questions?.behaviour?.map((ques: any, index: number) => (
          <>
            {/* {handleRenderComponent(ques.type, index, ques.question, ques.answer, ques.option, "gp_detail", ques.isRequired)} */}
            {<CommonField
              type={ques.type}
              index={index}
              question={ques.question}
              answer={ques.answer}
              options={ques.option}
              fileType={"behaviour"}
              required={ques.isRequired}
              question_number={QuestionNumber}
              questions={questions?.behaviour}
              isError={isError}
              handleAnswerChange={handleAnswerChange}
              ques={ques}
              setIsDisableContinue={setIsDisableContinue}
              totalQuestion={questions?.behaviour?.length}
              isAdditional={ques?.isAdditional}
              isAdditionalQuestion={ques?.isAdditionalStructure}
              handleAdditionalChange={handleAdditionalChange}
              patientName={patientName}
            />}

          </>
        ))}
      </Grid>
    </>
  );
}

export default Behavior;

import  { useEffect, useRef, useState } from "react";
import Button from "../../../components/atoms/Button";
import Grid from "@mui/material/Grid";
import SideStepper from "./sideStepper";
import PersonalDetail from "./PersonalDetail";
import Behavior from "./Behavior";
import CreativityImagination from "./CreativityImagination";
import AttentionLevels from "./AttentionLevels";
import PersonDetail from "./social_interaction";
import Communication from "./Communication";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import Sensory from "./Sensory";
import AdditionalParentDetail from "./OtherInformation";
import { question } from "./question";
import {
  addSchoolReport,
  editSchoolReport,
  getCaseloadById,
  getReportByID,
  setCaseloadCurentTab,
} from "../../../redux/action";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { createSelector } from "reselect";
import classes from "./index.module.scss";
import moment from "moment";
import ConfirmationModal from "../../../components/atoms/ConfirmationModal";

const CaseloadReducer = (state: any) => state?.CaseloadReducer;
const reportReducer = (state: any) => state?.schoolReportReducer;
const Index = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location: any = useLocation();
  const pathParts = location.pathname.split("/").filter(Boolean);
  const currentPage = pathParts[1];
  const id = pathParts[2];
  const schoolReportId = pathParts[3];
  const [activeStep, setActiveStep] = useState(0);
  const [isVisible, setIsVisible] = useState(false);
  const scrollContainerRef = useRef<HTMLDivElement>(null);
  const [enableStep, setEnableStep] = useState(0);
  const [isNewSchool, setIsNewSchool] = useState();
  const [completeSteps, setCompleteSteps] = useState<any>();
  const [questionData, setQuestionData] = useState<any>({});
  const [isError, setIsError] = useState(false);
  const [isCancelReport, setIsCancelReport] = useState(false);
  const [isSubmitReport, setIsSubmitReport] = useState(false);
  const [isDisableContinue, setIsDisableContinue] = useState(false);
  const deepCopyQuestion = () => JSON.parse(JSON.stringify(question));
  const userData =
    localStorage.getItem("loginResponse") !== null
      ? JSON.parse(localStorage.getItem("loginResponse")!)
      : {};
  const currentDate = moment().format("YYYY-MM-DD");
  const getDropdown = (response: any, name: string, last: string) => {
    let dropdown: any = [];
    response &&
      response?.map((value: any) => {
        dropdown.push({
          value: value.id,
          label: last ? value[name] + " " + value[last] : value[name],
          email: value.email,
          address: value.address,
          mobile: value.mobile,
        });
      });
    return dropdown;
  };

  const caseloadData = createSelector(CaseloadReducer, (state) => ({
    caseloadByIdData: state.caseloadByIdData,
  }));
  const reportData = createSelector(reportReducer, (state) => ({
    reportDetail: state.reportDetailById,
  }));

  const { caseloadByIdData } = useSelector(caseloadData);
  const { reportDetail } = useSelector(reportData);
  const checkAnswers = (arr: any) => {
    let hasNullAnswer = false;

    arr?.forEach((item: any) => {
      if (
        (item.answer === null ||
          item.answer === undefined ||
          item.answer === "") &&
        item.isRequired &&
        item.isCompulsory
      ) {
        setIsError(true);
        hasNullAnswer = true;
      }

      if (item.answer === "Yes") {
        if (
          item.isAdditional &&
          (item?.isAdditionalStructure?.answer === undefined ||
            item?.isAdditionalStructure?.answer === "") &&
          item.isAdditionalStructure?.isRequired
        ) {
          setIsError(true);
          hasNullAnswer = true;
        }
      }

      if (item?.isNested === true) {
        item?.isNestedStructure?.map((nested: any, ind: number) => {
          if (
            (nested?.answer === null ||
              nested?.answer === undefined ||
              nested?.answer === "") &&
            nested.isRequired
          ) {
            setIsError(true);
            hasNullAnswer = true;
          }
        });
      }
    });

    return hasNullAnswer;
  };
  const handleNext = () => {
    switch (activeStep) {
      case 0:
        if (checkAnswers(questionData.personalDetail)) {
          return;
        }
        break;
      case 1:
        if (checkAnswers(questionData.communication)) {
          return;
        }
        break;
      case 2:
        if (checkAnswers(questionData.socialInteraction)) {
          return;
        }
        break;
      case 3:
        if (checkAnswers(questionData.creativityImagination)) {
          return;
        }
        break;
      case 4:
        if (checkAnswers(questionData.behaviour)) {
          return;
        }
        break;
      case 5:
        if (checkAnswers(questionData.sensory)) {
          return;
        }
        break;
      case 6:
        if (checkAnswers(questionData.attentionActivityLevels)) {
          return;
        }
        break;
      case 7:
        if (checkAnswers(questionData.otherRelevantInformation)) {
          return;
        } else {
          setIsSubmitReport(true);

          return;
        }
        break;
      default:
        break;
    }

    setCompleteSteps(activeStep);

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setEnableStep((prevActiveStep) => prevActiveStep + 1);
    scrollToTop();
  };

  useEffect(() => {
    if (currentPage === "school-edit") {
      dispatch(getReportByID({ caseload_id: id }));
    } else {
      dispatch(getCaseloadById(id));
    }
    setQuestionData(deepCopyQuestion());
  }, [question]);

  const handleSubmit = () => {
    let payload = {
      data: questionData,
      total_count: 7,
      actual_count: 7,
      isFinalSubmit: true,
      caseload_id: id,
    };
    if (currentPage === "school-edit") {
      const { caseload_id, ...updatedParentDetail } = payload;
      dispatch(
        editSchoolReport(
          updatedParentDetail,
          schoolReportId,
          navigate,
          true,
          id
        )
      );
    } else {
      dispatch(addSchoolReport(payload, navigate, false, ""));
    }
    dispatch(setCaseloadCurentTab(1));
  };

  useEffect(() => {
    if (currentPage === "school-edit") {
      let tempQuestions = { ...reportDetail?.data?.schoolReport?.[0]?.data };
      setQuestionData(tempQuestions);
    } else {
      let tempQuestions = { ...deepCopyQuestion() };
      let tempPersonalDetail = [...tempQuestions?.personalDetail];
      let tempOtherInfo = [...tempQuestions?.otherRelevantInformation];
      let patientFirstName = caseloadByIdData?.patient_name?.split(" ")?.[0];
      let patientLastName = caseloadByIdData?.patient_name?.split(" ")?.[1];
      tempPersonalDetail[0].answer = patientFirstName;
      tempPersonalDetail[1].answer = patientLastName;
      tempPersonalDetail[2].answer = caseloadByIdData?.patient_dob;
      // tempPersonalDetail[2].answer = userData?.school_name;
      // tempPersonalDetail[5].answer = userData?.email;
      tempPersonalDetail[8].answer = currentDate;
      tempOtherInfo[6].answer = currentDate;
      setQuestionData({ ...tempQuestions, personalDetail: tempPersonalDetail });
      setQuestionData({
        ...tempQuestions,
        otherRelevantInformation: tempOtherInfo,
      });
    }
  }, [caseloadByIdData, reportDetail]);

  useEffect(() => {
    const toggleVisibility = () => {
      if (scrollContainerRef.current) {
        setIsVisible(scrollContainerRef.current.scrollTop > 200);
      }
    };

    if (scrollContainerRef.current) {
      scrollContainerRef.current.addEventListener("scroll", toggleVisibility);
    }

    return () => {
      if (scrollContainerRef.current) {
        scrollContainerRef.current.removeEventListener(
          "scroll",
          toggleVisibility
        );
      }
    };
  }, []);

  const scrollToTop = () => {
    if (scrollContainerRef.current) {
      scrollContainerRef.current.scrollTo({ top: 0, behavior: "smooth" });
    }
  };

  

  return (
    <Grid
      container
      spacing={4}
      className={classes.refContainer}
      marginTop={"10px"}
    >
      <Grid item lg={3} md={4} sm={4} xs={12} className={classes.pt0}>
        <SideStepper
          activeStep={activeStep}
          setActiveStep={setActiveStep}
          completeSteps={completeSteps}
          enableStep={enableStep}
        />
      </Grid>
      <Grid ref={scrollContainerRef} item lg={9} md={8} sm={8} xs={12}>
        <Grid>
          {activeStep === 0 && (
            <PersonalDetail
              caseloadDetail={caseloadByIdData}
              patientName={caseloadByIdData.patient_name}
              questions={questionData}
              setQuestionData={(e: any) => setQuestionData(e)}
              isError={isError}
              setIsError={setIsError}
              setIsDisableContinue={setIsDisableContinue}
            />
          )}
          {activeStep === 1 && (
            <Communication
              patientName={caseloadByIdData.patient_name}
              questions={questionData}
              setQuestionData={(e: any) => setQuestionData(e)}
              isError={isError}
              setIsError={setIsError}
              setIsDisableContinue={setIsDisableContinue}
            />
          )}
          {activeStep === 2 && (
            <PersonDetail
              patientName={caseloadByIdData.patient_name}
              questions={questionData}
              setQuestionData={(e: any) => setQuestionData(e)}
              isError={isError}
              setIsError={setIsError}
              setIsDisableContinue={setIsDisableContinue}
              setIsNewSchool={setIsNewSchool}
            />
          )}
          {activeStep === 3 && (
            <CreativityImagination
              patientName={caseloadByIdData.patient_name}
              questions={questionData}
              setQuestionData={(e: any) => setQuestionData(e)}
              isError={isError}
              setIsError={setIsError}
              setIsDisableContinue={setIsDisableContinue}
            />
          )}
          {activeStep === 4 && (
            <Behavior
              patientName={caseloadByIdData.patient_name}
              questions={questionData}
              setQuestionData={(e: any) => setQuestionData(e)}
              isError={isError}
              setIsError={setIsError}
              setIsDisableContinue={setIsDisableContinue}
            />
          )}
          {activeStep === 5 && (
            <Sensory
              patientName={caseloadByIdData.patient_name}
              questions={questionData}
              setQuestionData={(e: any) => setQuestionData(e)}
              isError={isError}
              setIsError={setIsError}
              setIsDisableContinue={setIsDisableContinue}
            />
          )}
          {activeStep === 6 && (
            <AttentionLevels
              patientName={caseloadByIdData.patient_name}
              questions={questionData}
              setQuestionData={(e: any) => setQuestionData(e)}
              isError={isError}
              setIsError={setIsError}
              setIsDisableContinue={setIsDisableContinue}
            />
          )}
          {activeStep === 7 && (
            <AdditionalParentDetail
              patientName={caseloadByIdData.patient_name}
              questions={questionData}
              setQuestionData={(e: any) => setQuestionData(e)}
              isError={isError}
              setIsError={setIsError}
              setIsDisableContinue={setIsDisableContinue}
            />
          )}
        </Grid>
        <Grid
          sx={{
            display: "flex",
            justifyContent: "center",
            gap: "10px",
            mt: "15px",
          }}
        >
          <Grid>
            <Button
              variant="outlined"
              onClick={() =>
                setActiveStep((prevActiveStep) => prevActiveStep - 1)
              }
              fullWidth={true}
              disabled={activeStep === 0}
            >
              Previous
            </Button>
          </Grid>

          <Grid>
            <Button
              variant="contained"
              onClick={() => handleNext()}
              fullWidth={true}
              // disabled={isDisableContinue}
            >
              {activeStep === 7
                ? `${currentPage === "school-edit" ? "Update" : "Submit"}`
                : "Continue"}
            </Button>
          </Grid>
          {isVisible && (
            <Grid className="posFixed">
              <Button variant="outlined" onClick={scrollToTop}>
                <ArrowUpwardIcon />
              </Button>
            </Grid>
          )}
        </Grid>
      </Grid>

      <>
        <ConfirmationModal
          open={isSubmitReport}
          handleClose={() => setIsSubmitReport(false)}
          title="Confirmation"
          message={`Are you sure you want to ${
            currentPage === "school-edit" ? "update" : "submit"
          } Report ?`}
          onConfirm={() => handleSubmit()}
          onCancel={() => setIsSubmitReport(false)}
        />
      </>
      <>
        <ConfirmationModal
          open={isCancelReport}
          handleClose={() => setIsCancelReport(false)}
          title="Confirmation"
          message="Are you sure you want to cancel Report ?"
          onConfirm={() => navigate(-1)}
          onCancel={() => setIsCancelReport(false)}
        />
      </>
    </Grid>
  );
};

export default Index;
